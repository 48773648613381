<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <!-- 单独公众号 -->
        <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="选择公众号"
          clearable
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <!-- 内容 -->
        <el-input
          style="width: 200px"
          v-model="params.content"
          placeholder="请输入内容"
          clearable
        ></el-input>
        <!-- 开始结束 -->
        <el-date-picker
          v-model="params.selectTime"
          style="width: 220px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <!-- 关注时间 -->
        <el-date-picker
          v-model="params.subDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择关注日期"
        >
        </el-date-picker>
        <!-- 分组 -->
        <el-select
          v-model="params.groupType"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="分组"
        >
          <el-option label="内容分组" :value="1"> </el-option>
          <el-option label="用户分组" :value="2"> </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 5px"
          :loading="loading"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div class="data-box" v-loading="loading">
      <!-- 表格数据 -->
      <el-table
        :data="tableData"
        style="width: 100%; height: 650px; overflow-y: auto"
      >
        <el-table-column prop="channelName" label="公众号" width="250">
        </el-table-column>
        <el-table-column prop="openid" label="openid" width="300">
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" width="300">
        </el-table-column>
        <el-table-column prop="saveDate" label="发送日期" width="250">
        </el-table-column>
        <el-table-column prop="subscribeTime" label="关注时间" width="250">
        </el-table-column>
        <el-table-column prop="contentStr" label="内容"> </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        :page-size="pageSize"
        @current-change="handlePageChange"
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { pickerOptions } from "@/assets/js/options";
import { formatTime } from "@/assets/js/utils";
import { getWechatKeyDetail } from "@/api/statics";
export default {
  data() {
    return {
      tableData: [],
      params: {},
      pickerOptions,
      pageSize: 15,
      total: 0,
      page: 1,
      loading: false,
    };
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.loading = true;
      getWechatKeyDetail({
        page: this.page,
        pageSize: this.pageSize,
        startDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        content: this.params.content,
        subDate: this.params.subDate,
        accountChannelId: this.params.accountChannelId,
        groupType: this.params.groupType,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  created() {
    this.params = {
      selectTime: [
        // formatTime(new Date().getTime() - 3600 * 1000 * 24 * 7, "yyyy-MM-dd"),
        formatTime(new Date(), "yyyy-MM-dd"),
        formatTime(new Date(), "yyyy-MM-dd"),
      ],
      groupType: 1,
    };
    this.handlePageChange();
  },
};
</script>

<style lang='scss' scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
</style>